<template>
    <section>
        <modal ref="modalInformacionCedis" titulo="Información de Cedis" :no-aceptar="true" tamano="modal-xl">
            <div class="row">
                <div class="col px-5">
                    <div class="row mx-0">
                        <div class="col-auto text-center">
                            <img :src="cedis.imagen" width="160" height="160" class="br-12 obj-cover border" />
                            <p class="f-600 my-2 text-general-red"> Cedis {{ cedis.estado ? 'Activo' : 'Inactivo' }} </p>
                            <img :src="cedis.responsable.imagen" width="62" height="62" class="rounded-circle mt-4 obj-cover border" />
                            <p class="f-600 my-2 text-general"> {{ cedis.responsable.nombre }} </p>
                            <p class="my-1 f-14 text-general"> Responsable </p>
                        </div>
                        <div class="col px-0">
                            <div class="row mx-0 my-3">
                                <p class="f-600 col-auto text-general">
                                    {{ cedis.nombre }}
                                </p>
                                <div class="col-auto ml-auto">
                                    <div class="br-12">
                                        <i class="icon-pencil-outline f-22 cr-pointer hover-icons" @click="editarCedis" />
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="br-12">
                                        <i class="icon-trash f-22 hover-inverse cr-pointer" @click="eliminar" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col-auto">
                                    <p>
                                        <i class="icon-currency-usd-circle text-general f-19" />
                                        <span class="text-general f-400">
                                            {{ separadorNumero(cedis.valores.valores) }} {{ moneda.sigla }}
                                        </span>
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        <i class="icon-package-variant-closed text-general f-20" />
                                        <span class="text-general f-400">
                                            {{ agregarSeparadoresNumero(cedis.valores.productos) }} Productos
                                        </span>
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <p>
                                        <i class="icon-format-list-checks text-general f-20" />
                                        <span class="text-general f-400">
                                            {{ agregarSeparadoresNumero(cedis.valores.unidades) }} Unidades
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col">
                                    <i class="icon-location f-20 text-general" />
                                    <span class="text-general f-15">{{ cedis.direccion_completa }}</span>
                                </div>
                                <div class="col-auto">
                                    <i class="icon-phone f-20 text-general" />
                                    <span class="text-general f-15">
                                        {{ cedis.telefono }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col">
                                    <i class="icon-warehouse f-20 text-general" />
                                    <span class="text-general f-15">{{ cedis.id_cedis_stock ? 'Almacena inventario en Cedis diferente' : 'Stock Propio' }}</span>
                                </div>
                                <div class="col-auto">
                                    <i class="icon-cash-plus f-25 text-general" />
                                    <span class="text-general f-15">
                                        {{ cedis.impuestos ? 'Aplica impuestos' : 'No aplica impuestos' }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-12 mt-4">
                                    <span class="text-general f-15">
                                        {{ cedis.descripcion }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col p-0 px-0 cr-pointer" @click="abrirModalMapa">
                                    <mapa ref="mapaPeq" height-mapa="250px" :coordenadas="coordenadas" icono="cedis-point-map.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modalEliminar ref="modalEliminar" titulo="Eliminar cedis" mensaje="¿Realmente desea eliminar este cedis? Esta acción no puede ser revertida." @eliminar="delete_cedis" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        icono-central="/img/icons/cedis-point-map.svg"
        :desactivar="true"
        :coordenadas-entrada="coordenadas"
        />
        <modal-editar-cedis ref="editarCedis" @listar="info_cedis" />
    </section>
</template>

<script>
import Cedis from "../../../services/cedis/index";
import { mapGetters } from 'vuex'

export default {
    components: {
        modalEditarCedis: () => import('./modalEditarCedis')
    },
    data(){
        return{
            coordenadas: {
                lat: 0,
                lng: 0
            },
            cedis:{
                id: null,
                estado: null,
                cedis_inventario: false,
                nombre: null,
                descripcion: null,
                foto: null,
                direccion: null,
                impuestos:null,
                telefono: null,
                direccion_completa: null,
                id_cedis_stock: null,
                longitud: null,
                latitud: null,
                moneda: {
                    id: null,
                    nombre: null,
                    sigla: null,
                },
                responsable: {
                    id: null,
                    nombre: null,
                    imagen: null
                },
                valores: {
                    productos: null,
                    unidades: null,
                    valores: null
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            moneda:'cedis/moneda',
            id_moneda:'cedis/id_moneda',
        })
    },
    methods: {
        toggle(){
            this.info_cedis()
            this.$refs.modalInformacionCedis.toggle();
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        editarCedis(){
            let model = {}
            model.id = this.cedis.id
            model.estado = this.cedis.estado
            model.cedis_inventario = this.cedis.cedis_inventario
            model.nombre = this.cedis.nombre
            model.direccion = this.cedis.direccion
            model.impuestos = this.cedis.impuestos
            model.telefono = this.cedis.telefono
            model.idm_moneda = this.cedis.idm_moneda
            model.idm_ciudad = this.cedis.idm_ciudad
            model.idm_estado = this.cedis.idm_estado
            model.id_cedis_stock = this.cedis.id_cedis_stock
            model.idm_pais = this.cedis.idm_pais
            model.descripcion = this.cedis.descripcion
            model.latitud = this.cedis.latitud
            model.longitud = this.cedis.longitud
            model.id_user = this.cedis.id_user
            model.foto = this.cedis.foto
            model.imagen = this.cedis.imagen

            console.log(model);
            this.$refs.modalInformacionCedis.toggle();
            this.$refs.editarCedis.toggle(model)
        },
        eliminar(){
            this.$refs.modalEliminar.toggle()
            this.$refs.modalInformacionCedis.toggle();
        },
        async delete_cedis(){
            try {
                const {data} = await Cedis.delete_cedis(this.$route.params.id_cedis)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalEliminar.toggle()
                this.$router.push({name:'admin.cedis'})

            } catch (e){
                this.error_catch(e)
            }
        },
        async info_cedis(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.$route.params.id_cedis,
                }
                const {data} = await Cedis.info_cedis(params)
                this.cedis = data
                this.coordenadas.lat = +this.cedis.latitud
                this.coordenadas.lng = +this.cedis.longitud
                this.$refs.mapaPeq.setPosition()

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px;}
.hover_general{
    background: #fff;
    color: var(--gr-r-general);
    &:hover{
        background: var(--gr-r-general);
        color: #fff;
    }
}
.hover_red{
    background: #fff;
    color: var(--gr-r-red);
    &:hover{
        background: var(--gr-r-red);
        color: #fff;
    }
}
</style>
